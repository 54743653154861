import React from 'react';
import { IncreaseIsologo } from '../../components/IncreaseIsologo';
// import { ClickDePagoIsoLogoNegativo } from '../../components/ClickDePagoIsoLogo';
import { HeaderLink } from './HeaderLink';
import styled, { useTheme, css } from 'styled-components';
import { Container } from '../../components/Container';
import { CTAButton } from '../../components/CTAButton';
import { ThemeProvider } from '@increasecard/typed-components';
import { HeaderMobileMenu } from './HeaderMobileMenu';
import { MEDIUM } from '../../breakpoints';
import { SmartLink } from '../../components/SmartLink';
import { HEADER_HEIGHT } from '../../constants';
import { WhatsappIcon } from '../../icons/WhatsappIcon';
import { useLocation } from '@reach/router';

function getLogoColor({ theme, variant }) {
  switch (variant) {
    case 'enterprise':
    case 'increase':
      return theme.colorsNew.increase.accent;
    case 'increase-dark':
      return theme.colorsNew.gray.white;
    case 'default':
    default:
      return theme.colorsNew.increase.regular;
  }
}

function getLoginButtonType({ variant }) {
  switch (variant) {
    case 'enterprise':
      return 'accent-outline';
    case 'increase':
    case 'increase-dark':
      return 'white-outline';
    default:
      return 'product-outline';
  }
}

function upperBarColors({ theme, variant }) {
  let background, color;
  switch (variant) {
    case 'enterprise':
      background = theme.colorsNew.gray.black;
      color = theme.colorsNew.gray.white;
      break;
    case 'increase':
      background = theme.colorsNew.increase.dark30;
      color = theme.colorsNew.gray.white;
      break;
    case 'increase-dark':
      background = theme.colorsNew.increase.dark;
      color = theme.colorsNew.gray.white;
      break;
    default:
      background = theme.colorsNew.gray.white;
      color = theme.colorsNew.gray.black;
  }
  return css`
    background: ${background};
    color: ${color};
  `;
}

const HeaderOuterWrapper = styled.nav`
  position: fixed;
  width: 100vw;
  height: 56px;
  top: 0;
  z-index: 1;
  @media (min-width: ${MEDIUM}) {
    height: ${HEADER_HEIGHT};
    width: 100%;
    ${upperBarColors}
  }
`;

const HeaderInnerWrapper = styled(Container)`
  display: flex;
  width: calc(100% - 16px);
  justify-content: ${({ justify }) => justify};
  align-items: center;
  padding: 12px 12px 12px 8px;
  margin: 8px;
  box-shadow: ${({ theme }) => theme.shadows.rest};
  border-radius: 9px;

  ${upperBarColors}

  @media (min-width: ${MEDIUM}) {
    margin: 0 auto;
    padding: 16px;
    box-shadow: none;
    border-radius: 0;
    align-items: center;
    height: ${HEADER_HEIGHT};
  }
`;

const LogoWrapper = styled(SmartLink)`
  svg {
    width: 144px;
    @media (min-width: ${MEDIUM}) {
      width: 160px;
    }
  }
`;

const HeaderLinkList = styled.ul`
  display: none;

  @media (min-width: ${MEDIUM}) {
    padding: 0 32px;
    display: grid;
    grid-template-columns: repeat(${({ columns }) => columns}, max-content);
    grid-column-gap: 32px;
    align-items: center;
  }
`;

export function Header({ links, variant, layout, whatsappHref }) {
  const theme = useTheme();
  const logoColor = getLogoColor({ theme, variant });
  const isNavigationEnabled = layout === 'fullNavigation';
  const location = useLocation();

  return (
    <ThemeProvider>
      <HeaderOuterWrapper variant={variant}>
        <HeaderInnerWrapper
          variant={variant}
          justify={layout === 'logoOnly' ? 'center' : 'space-between'}
        >
          <LogoWrapper aria-label="Logo" href="/">
            {location.pathname.match(/terminos|politica/) ? null : <IncreaseIsologo logoColor={logoColor} />}
          </LogoWrapper>
          {layout === 'logoAndWhatsapp' && <WhatsappLink href={whatsappHref} />}
          {isNavigationEnabled && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <HeaderLinkList columns={links.length + 1}>
                {links.map(({ name, url, sublinks, visible }) => {
                  if (!visible) return null;
                  return (
                    <li key={url} style={{ gridColumn: 'span 1 / span 1' }}>
                      <HeaderLink
                        href={url}
                        sublinks={sublinks}
                        name={name}
                        variant={variant}
                      />
                    </li>
                  );
                })}
              </HeaderLinkList>
              <CTAButton
                type={getLoginButtonType({ variant })}
                size="small"
                text="Iniciar sesión"
                href="https://auth.increase.app/login"
              />
              <HeaderMobileMenu variant={variant} links={links} />
            </div>
          )}
        </HeaderInnerWrapper>
      </HeaderOuterWrapper>
    </ThemeProvider>
  );
}

function WhatsappLink({ href }) {
  return (
    <CTAButton
      as="a"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{ width: 'auto' }}
      text={
        <>
          <WhatsappIcon style={{ marginRight: '8px' }} /> WhatsApp
        </>
      }
    />
  );
}
